import { IRetailer } from '~/types/retailer'
import { ICoords } from '~/types/postcode'
import { NonNullableInterface } from '~/types'

export const useRetailerSearch = (
  onRetailerSearch: (data: { Retailers: IRetailer[] }) => any,
  onRetailerChange?: (data: IRetailer | null) => void,
  initialValues?: Partial<{
    query: string
    distance: number
    isSmart: boolean
  }>
) => {
  const defaultValues = {
    query: '',
    distance: 100,
    isSmart: false,
    ...initialValues
  }

  const { $api } = useNuxtApp()

  const retailers = ref<IRetailer[]>([])
  const query = ref(defaultValues.query)
  const distance = ref<number>(defaultValues.distance)

  const isLoading = ref<boolean>(false)
  const isDropdownOpen = ref(false)
  const errorBag = ref(new ErrorBag())

  function containsNumber(string: string): boolean {
    return /\d/.test(string)
  }

  function retailerDescription(retailer: IRetailer | null) {
    if (defaultValues.isSmart && retailer?.smart) {
      return retailer?.smartDescription || retailer?.Description || ''
    }

    return retailer?.Description || ''
  }

  const isPostcode = computed(() => containsNumber(query.value))

  const rules = computed(() => {
    return isPostcode.value ? 'postcode_prefilter' : ''
  })

  const updateRetailer = (retailer: IRetailer | null) => {
    if (typeof onRetailerChange !== 'function') return

    onRetailerChange(retailer)
  }

  /**
   * Input handling
   */
  const isValidPostcode = computed(() => {
    return /^([A-Z]{1,2}[A-Z\d]{0,2})\s*?(\d[A-Z]{0,2})$/i.test(query.value)
  })

  function handleInput(value: string, retailer?: IRetailer | null) {
    if (value && value === retailerDescription(retailer || null)) return

    updateRetailer(null)
    query.value = value
    errorBag.value.clear()

    onInput(value)
  }

  const onInput = useDebounce(async (value: string) => {
    await nextTick()

    isDropdownOpen.value = false

    if (value.length < 3) return

    if (containsNumber(value)) {
      if (isValidPostcode.value) {
        await searchByPostcode(value)
      }
    } else {
      await searchByName(value)
    }
  }, 500)

  /**
   * Location
   */
  const position = ref<NonNullableInterface<ICoords>>({
    latitude: 0,
    longitude: 0
  })

  async function onLocated(newPosition: {
    coords: NonNullableInterface<ICoords>
  }) {
    query.value = ''
    position.value.latitude = newPosition.coords.latitude || 0
    position.value.longitude = newPosition.coords.longitude || 0

    await searchByLocation()
  }

  /**
   * Searching
   */
  const searchByName = async (value: string): Promise<any> => {
    return await search(value)
  }

  async function searchByPostcode(value: string): Promise<any> {
    const payload: NearestRetailerPayload = {
      Radius: {
        Postcode: value,
        Distance: distance.value
      }
    }

    await search(payload)
  }

  const searchByLocation = async (): Promise<any> => {
    const payload: NearestRetailerPayload = {
      Radius: {
        Lat: position.value.latitude,
        Lon: position.value.longitude,
        Distance: distance.value
      }
    }

    await search(payload)
  }

  async function search(
    payload: NearestRetailerPayload | string
  ): Promise<any> {
    isLoading.value = true

    const res =
      typeof payload === 'string'
        ? await $api.retailers.search(payload)
        : await $api.retailers.searchNearest(payload)

    isLoading.value = false

    if (res.error) {
      errorBag.value.add('Something went wrong, please try again')
      return
    }

    isDropdownOpen.value = true
    retailers.value = res.data.Retailers
    onRetailerSearch(res.data)
  }

  /**
   * User interactions
   */
  function selectRetailer(retailer: IRetailer) {
    updateRetailer(retailer)
    retailers.value = []

    query.value = retailerDescription(retailer)
    isDropdownOpen.value = false
  }

  function resetRetailer() {
    query.value = ''
    updateRetailer(null)
  }

  function close() {
    isDropdownOpen.value = false
  }

  return {
    rules,
    isDropdownOpen,
    retailers,
    query,
    isLoading,
    distance,
    onInput,
    errorBag,
    handleInput,
    retailerDescription,
    onLocated,
    selectRetailer,
    resetRetailer,
    close,

    searchByLocation,
    searchByPostcode,
    searchByName,
    containsNumber
  }
}
