<template>
  <div
    class="postcodeLookup"
    :class="{
      'postcodeLookup--validate': !!rules && (locationEnabled || isLoading)
    }"
  >
    <InputText
      v-model="locationModel"
      :name="name || label"
      :placeholder="label"
      :aria-label="label"
      :validate="!!rules"
      :rules="rules"
      :pattern="'[a-zA-Z0-9\- ]+'"
      @focus="$emit('focus')"
    />

    <OsIcon
      v-if="isLoading"
      name="Loader"
      class="postcodeLookup__loader text-blue-600"
    />

    <OsIcon
      v-if="!isLoading && isGeolocationEnabled"
      name="Location"
      :class="{ 'animation--pulse-fade': isLocating }"
      class="postcodeLookup__loader text-blue-600"
      @click="fetchLocation"
    />

    <ul v-if="showResults" class="postcodeLookup__list" data-locator="results">
      <li
        v-for="(result, index) in results"
        :key="result.Id"
        class="postcodeLookup__list__item"
      >
        <button
          data-locator="os-button"
          class="postcodeLookup__list__button"
          @click.stop.prevent="emit('selected', result, index)"
        >
          <slot :result="result" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { ICoords } from '~/types/postcode'
import { IGoogleDataLayerOptions } from '~/types/googleDataLayer'

export default {
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    results: {
      type: Array as PropType<{ Id: any }[]>,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    locationEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
    rules: {
      type: String,
      required: false,
      default: undefined
    },
    hideResults: {
      type: Boolean,
      required: false,
      default: false
    },
    trackingOptions: {
      type: Object as PropType<Partial<IGoogleDataLayerOptions>>,
      default: null,
      required: false
    }
  },

  emits: ['selected', 'located', 'update:modelValue', 'focus'],

  setup(props, { emit }) {
    const { $dataLayer } = useNuxtApp()
    const isLocating = ref<boolean>(false)

    const geolocationAccess = usePermission('geolocation')

    const isGeolocationEnabled = computed<boolean>(() => {
      if (!props.locationEnabled) return false

      if (geolocationAccess.value === 'denied') return false

      return true
    })

    const locationModel = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    const showResults = computed<boolean>(() =>
      props.hideResults ? false : props.results.length > 0
    )

    const fetchLocation = (): void => {
      isLocating.value = true

      $dataLayer.linkClick({
        action: 'geolocator icon',
        ...props.trackingOptions
      })

      const onSuccess = (position: { coords: ICoords }) => {
        isLocating.value = false

        emit('located', position)
      }

      navigator.geolocation.getCurrentPosition(onSuccess, () => {
        isLocating.value = false
      })
    }

    return {
      isLocating,
      showResults,
      fetchLocation,
      emit,
      locationModel,
      isGeolocationEnabled
    }
  }
}
</script>

<style lang="scss">
.postcodeLookup {
  position: relative;

  .textInput__input {
    padding-right: 26px;
  }
}
.postcodeLookup__loader {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 30px;
  margin-top: -10px;
}

.postcodeLookup__list {
  border: 1px solid $grey--light;
  border-radius: 0 0 4px 4px;
  background-color: $white;
  cursor: pointer;
  list-style-type: none;
  margin-top: rem(-1);
  max-height: 190px;
  overflow: auto;
  padding: rem(8) 0;
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 20;
}

.postcodeLookup__list__item {
  display: flex;
  cursor: pointer;
  width: 100%;
}

.postcodeLookup__list__button {
  border: 0;
  border-radius: 4px;
  color: $grey--darkest;
  cursor: pointer;
  display: flex;
  font-family: $mb-font-text;
  font-size: rem(16);
  justify-content: space-between;
  letter-spacing: -0.2px;
  margin: 0 auto;
  padding: 10px 6px;
  text-align: left;
  transition: 0.2s linear;
  width: calc(100% - 1.6rem);

  .postcodeLookup__list__item:last-child & {
    border-bottom: 0;
  }

  .postcodeLookup__list__item:hover & {
    background-color: rgba(215, 215, 215, 0.5);
  }
}

.postcodeLookup--validate {
  .textInput__tickIcon,
  .textInput__errorIcon {
    right: 36px;
  }
}
</style>
