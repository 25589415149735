export class ErrorBag {
  public errors: string[] = []

  constructor(errors: string[] = []) {
    this.record(errors)
  }

  record(errors: string[]) {
    this.errors = errors
  }

  add(error: string) {
    this.errors.push(error)
  }

  clear() {
    this.errors = []
  }

  includes(subject: string): boolean {
    return this.errors.includes(subject)
  }

  get all(): string[] {
    return this.errors
  }

  get first(): string | undefined {
    return this.errors[0]
  }

  get hasErrors(): boolean {
    return this.errors.length > 0
  }
}

export class FormErrorBag extends ErrorBag {
  errorTitle = 'Sorry something went wrong.'
  buttonText = 'Next: Start again'
  trackingOptions: object = {}
  showDefaultError = true
  showStartAgainButton = true

  constructor(props: Partial<FormErrorBag> = {}) {
    super(props?.errors)
    this.reset()
    this.update(props)
  }

  setErrorTitle(val: string) {
    this.errorTitle = val
  }

  setButtonText(val: string) {
    this.buttonText = val
  }

  setTrackingOptions(val: object) {
    this.trackingOptions = val
  }

  setShowDefaultError(val: boolean) {
    this.showDefaultError = val
  }

  setShowStartAgainButton(val: boolean) {
    this.showStartAgainButton = val
  }

  update(props: Partial<FormErrorBag>) {
    props.errors?.map((e) => super.add(e))
    this.errorTitle = props?.errorTitle ?? this.errorTitle
    this.buttonText = props?.buttonText ?? this.buttonText
    this.trackingOptions = props?.trackingOptions ?? this.trackingOptions
    this.showDefaultError = props?.showDefaultError ?? this.showDefaultError
    this.showStartAgainButton =
      props?.showStartAgainButton ?? this.showStartAgainButton
  }

  reset() {
    super.clear()
    this.errorTitle = 'Sorry something went wrong.'
    this.buttonText = 'Next: Start again'
    this.trackingOptions = {}
    this.showDefaultError = true
    this.showStartAgainButton = true
  }
}
